import React, { useState } from "react";
import HelpImage from "../../images/help.png";
// import { Link } from "react-router-dom";
// import TrainingPopup from "../../pages/trainingvideos/TrainingPopup";
// import { callAPI } from "../../services/apiService";

const Guide = ({
  to = "https://buildmystory.com/guide/",
  https: className = "",

  screen = "",

  // user = null,
}) => {
  // const [showPopUp, setshowPopUp] = useState(false);

  // const onClose = async () => {
  //   try {
  //     await callAPI("user/updateUser", {
  //       email: user?.email,
  //       firstName: user?.firstName,
  //       lastName: user?.lastName,
  //       settings: user?.settings,
  //       screentoUpdate: screen,
  //     });
  //   } catch (error) {
  //     console.log("error updating user training screen", error.message);
  //   } finally {
  //     setshowPopUp(false);
  //   }
  // };

  // console.log("guide", showPopUp, user?.showTrainingPopup[screen], to);
  return (
    <div
      className={`flex items-center ml-2 mt-2 ${className}`}
      // onClick={() => {
      //   setshowPopUp(true);
      // }}
    >
      {/* {showPopUp === true && user?.showTrainingPopup[screen] === true && (
        <TrainingPopup
          videoUrl={to}
          onClose={onClose}
          showScreenName={screen}
          user={user}
          focus={showPopUp}
        />
      )} */}
  
      <div
        className={`relative h-[30px] md:h-[30px] rounded-full `}
      >
        
        
          <a href="https://buildmystory.com/guide/" target="_blank" rel="noopener noreferrer">
            <img className="h-[30px]  w-[30px]" src={HelpImage} alt="Build My Story Guide" title="Guide" />
          </a>
     

        {/* <img className="h-full w-full" src={HelpImage} alt="Build My Story Guide" title="Guide" /> */}
      </div>
      {/* </Link> */}
    </div>
  );
};

export default Guide;
