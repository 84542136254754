import { LoadBundleTask } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/Common/Loader";

const TrainingPopup = ({ videoUrl, onClose, showScreenName, user, focus }) => {
  const [loading, setLoading] = useState(true);

  const modalRef = useRef(null);

  // Set focus to the modal when it is rendered
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }

    if (videoUrl) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    //  setTimeout(() => {
    //   setLoading(false);
    //  }, 3000);
  }, [modalRef.current]);

  // console.log("show videoUrl", videoUrl);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
      <div
        className={`bg-white rounded-2xl shadow-lg w-full max-w-xl relative px-8 pb-8`}
        ref={modalRef}
        tabIndex="-1"
        style={{
          width: "560px",
          height: "380px",
          outline: "none",
          border: "none",
        }}
      >
        <button
          onClick={onClose}
          className="absolute top-[.4rem] right-[.4rem] text-gray-100 "
          aria-label="Close"
          type="button"
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-4 h-4 text-storyblue"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg> */}
          <div className="flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4 text-storyblue"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </button>
        <div className="pt-1 mb-0">
          {/* Modal Title */}
          {showScreenName === "screen1" ? (
            <h2 className="text-storyblue text-xl text-center">Story Details</h2>
          ) : showScreenName === "screen2" ? (
            <h2 className="text-storyblue text-xl  text-center">Character Builder</h2>
          ) : showScreenName === "screen3" ? (
            <h2 className="text-storyblue text-lg font-semibold text-center">Story Editor</h2>
          ) : showScreenName === "screen4" ? (
            <h2 className="text-storyblue text-xl text-center">Purchase and View</h2>
          ) : null}

          {/* Loader or iframe */}
          <div
            className=" test flex items-center justify-center w-full "
            //  h-[calc(100%-3rem)]
          >
            {loading ? (
              <div className="flex items-center justify-center  w-full h-full">
                <Loader loader={loading} />
              </div>
            ) : (
              <iframe
                src={videoUrl}
                // src='https://www.youtube.com/embed/9gVSF7VfgOc?list=PLEIwzgL8RMADWE-qFDN-PfwPlbCbVXxfv'
                title="Training Video"
                width="500"
                height="315"
                className={`w-full pt-2 ${loading ? "hidden" : ""}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onLoad={() => setLoading(false)}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingPopup;

