import { useNavigate } from "react-router-dom";
import Button from "../../components/Common/Button";
import { Toast } from "../../components/Common/Toast";
import {
  CHARACTER_AGE_UNIT,
  CHARACTER_TYPE_ANIMAL,
  CHARACTER_TYPE_FICTIONAL,
  CHARACTER_TYPE_HUMAN,
  DEDUCT_CREDITS_PER_IMAGE,
  DUMMY_IMAGE,
  LOCAL_STORAGE_EDIT_MODE,
  MID_JOURNEY_MODE_RELAX,
  PROMO_PLAN,
  // STORY_CHARCTER_BUILDER_VIDEO,
  STORY_STEP_BASIC_INFO,
  STORY_STEP_ILLUSTRATION_CREATE,
} from "../../helper/Constant";
import CharacterDescriptionInputs from "./CharacterDescriptionInputs";
import Uploader from "./Uploader";
import { handleSubmitStepTwo, saveCharacterForImagePrompt } from "./builderHelpers";
import CharacterLibrary from "./CharacterLibrary";
import { callAPI } from "../../services/apiService";
import { setCurrentStoryStep } from "../../helper/helper";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useStory } from "../../context/storyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
// import DummyPic from "//"../../../public/dummy.png";//"../../components/Image holder Icon.png";
import { useApp } from "../../context/appContext";
import { useUser } from "../../context/userContext";

// import HelpImage from "../../images/help.png";
import Guide from "../../components/Common/Guide";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/Common/Modal";
import TrainingPopup from "../trainingvideos/TrainingPopup";

const StepCharacterBuilder = ({
  setStateData,
  setLibrary,
  setStartInterval,
  setImageUploading,
  setCharacterDescription,
  setLoaders,
  setCharacterOutlineByUser,
  setSelectedImage,
  setUploadedImageUrl,
  setCurrentPortraitList,
  setCurrentPortrait,
  setPreviewImage,
  setShowCharacterLibrary,
  setCurrentStep,
  showCharacterLibrary,
  library,
  loaders,
  currentStep,
  imageUploading,
  draftId,
  resetStory,
  selectedImage,
  currentPortrait,
  showFileInput,
  characterGenerationProgress,
  uploadedImageUrl,
  currentPortraitList,
  previewImage,
  storyPreview,
  characterOutlineByUser,
  // userCreditBalance,
  bookId,
  artStyle,
  stateData,
  getCharacterLibary,
  // imageCharacterPrompt,
  setimageCharacterPrompt,
  resetCharacter,
  selectedArtStyle,
  // setSelectedArtStyle,
}) => {
  const navigateTo = useNavigate();
  const { user, trainingVideos } = useUser();
  const { storyNewCharacter, setStoryData, storyData } = useStory();
  const [continueWithoutImage, setContinueWithoutImage] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabledGenerateNewCharacter, setDisabledGenerateNewCharacter] = useState(true);
  const [shouldCoverPortrait, setShouldCoverPortrait] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showRelaxModeModal, setShowRelaxModeModal] = useState(false);

  const { bookData  } = useApp();

  useEffect(() => {
    // if (characterOutlineByUser) {
    if (!characterOutlineByUser?.ageUnit) {
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        ["ageUnit"]: CHARACTER_AGE_UNIT,
      }));
    }
    if (!characterOutlineByUser?.age) {
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        ["age"]: 1,
      }));
    }
    if (
      !characterOutlineByUser?.characterType ||
      characterOutlineByUser.characterType === CHARACTER_TYPE_HUMAN
    ) {
      setCharacterOutlineByUser((prevState) => ({
        ...prevState,
        ["characterType"]: CHARACTER_TYPE_HUMAN,
        ["character"]: CHARACTER_TYPE_HUMAN,
      }));
    }
    // }
  }, []);

  useEffect(() => {
    if (bookData?.isImageProcessing === true) {
      setStoryData((prev) => ({
        ...prev,
        illustrationCharacter: {
          ...prev.illustrationCharacter,
          isPortraitDeleted: false,
        },
      }));
    } else {
      if (!bookData?.illustrationCharacter && !storyData?.illustrationCharacter?.pageIndex) {
        setStoryData((prevData) => ({
          ...prevData,
          uploadedImage: bookData?.uploadedImageUrl || DUMMY_IMAGE,
        }));
      } else if (
        bookData?.illustrationCharacter?.pageIndex === storyData?.illustrationCharacter?.pageIndex &&
        storyData?.illustrationCharacter?.isPortraitDeleted === false
      )
        setStoryData((prevData) => ({
          ...prevData,
          uploadedImage:
            bookData?.illustrationCharacter?.uploadedImage ||
            storyData?.illustrationCharacter?.pageData?.uploadedImage ||
            DUMMY_IMAGE,
        }));
      else {
        const isCustomCharacterId = !!storyData?.illustrationCharacter?.pageData?.customCharacterId;
        if (isCustomCharacterId) {
          setStoryData((prevData) => ({
            ...prevData,
            uploadedImage:
              storyData?.illustrationCharacter?.pageData?.uploadedImage ||
              DUMMY_IMAGE,
          }));
        } else {
          setStoryData((prevData) => ({
            ...prevData,
            uploadedImage:
              storyData?.illustrationCharacter?.pageData?.uploadedImage ||
              bookData?.uploadedImageUrl ||
              DUMMY_IMAGE,
          }));
        }
      }

      //Load Character Ourliner for this spacific PAGE
      if (!bookData?.illustrationCharacter && !storyData?.illustrationCharacter?.pageIndex) {
        const outliner = bookData?.characterOutlineByUser || {};
        loadCharacterOutline(outliner);

        // const keys = Object.keys(outliner || {});
        // keys?.map((key) => {
        //   setStoryData((prevState) => ({
        //     ...prevState,
        //     [key]: outliner[key],
        //   }));
        // });
      } else if (
        storyData?.illustrationCharacter?.pageData?.characterOutlineByUser &&
        storyData?.regeneratedNewCharacter === false
      ) {
        const outliner = storyData?.illustrationCharacter?.pageData?.characterOutlineByUser || {};
        loadCharacterOutline(outliner);
        // const keys = Object.keys(outliner);
        // keys?.map((key) => {
        //   setStoryData((prevState) => ({
        //     ...prevState,
        //     [key]: outliner[key],
        //   }));
        // });
      } else if (storyData?.regeneratedNewCharacter === false) {
        // Get character outiner from Book
        const outliner = bookData?.characterOutlineByUser || {};
        loadCharacterOutline(outliner);
        // const keys = Object.keys(outliner || {});
        // keys?.map((key) => {
        //   setStoryData((prevState) => ({
        //     ...prevState,
        //     [key]: outliner[key],
        //   }));
        // });
      } else if (storyData?.regeneratedNewCharacter === true) {
        const outliner = bookData?.illustrationCharacter?.characterOutlineByUser || {};
        loadCharacterOutline(outliner);
        // const keys = Object.keys(outliner || {});
        // keys?.map((key) => {
        //   setStoryData((prevState) => ({
        //     ...prevState,
        //     [key]: outliner[key],
        //   }));
        // });
      }
    }
  }, [bookData]);

  // console.log("storrrr", storyData, "BOOK", bookData);

  //&& storyData?.illustrationCharacter?.newRefImageUploaded === false
  useEffect(() => {
    // console.log("use effect in character builder ");
    if (
      bookData?.illustrationCharacter &&
      bookData?.illustrationCharacter?.pageIndex === storyData?.illustrationCharacter?.pageIndex &&
      storyData?.illustrationCharacter?.isPortraitDeleted === false
    ) {
      // console.log("first")
      setCurrentPortrait(bookData?.illustrationCharacter?.characterPortrait);
      setCurrentPortraitList(bookData?.illustrationCharacter?.currentCharacterPortraitList || []);
    } else if (
      storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl &&
      storyData?.illustrationCharacter?.isPortraitDeleted === false &&
      storyData?.illustrationCharacter?.newRefImageUploaded === false
    ) {
      // console.log("second")
      setCurrentPortrait(storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl);
      let images = storyData?.illustrationCharacter?.pageData?.images;
      let portraitList = [];
      images?.map((img) => {
        portraitList.push(img?.imageURL);
      });
      // console.log("img", portraitList);
      setCurrentPortraitList(portraitList);
    } else if (
      !bookData?.illustrationCharacter &&
      !storyData?.illustrationCharacter?.pageIndex &&
      storyData?.uploadedImage &&
      storyData?.regeneratedNewCharacter === false &&
      storyData?.illustrationCharacter?.isPortraitDeleted === false
    ) {
          //  console.log("third");
      setCurrentPortrait(storyData?.uploadedImage);
      // setCurrentPortraitList(bookData?.currentCharacterPortraitList || []);
      setCurrentPortraitList([]);
    } else if (
      storyData?.illustrationCharacter?.pageIndex &&
      storyData?.illustrationCharacter?.newRefImageUploaded === true
    ) {
          //  console.log("fourth");
      setCurrentPortrait(storyData?.uploadedImage);
      setCurrentPortraitList([]);
    } else if (storyData?.illustrationCharacter?.isPortraitDeleted === true || !bookData?.uploadedImageUrl) {
          //  console.log("last");
      setCurrentPortrait(DUMMY_IMAGE);
      setCurrentPortraitList([]);
    } else if (
      (storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl && !storyData?.illustrationCharacter?.uploadedImage &&
      bookData?.illustrationCharacter === null)
    ) {
  //  console.log("last if else")
      setCurrentPortrait(storyData?.illustrationCharacter?.pageData?.imagePromptCharacterUrl);
      setCurrentPortraitList([]);
    } 
    // else {
    //   console.log("lasssssssstttttttttt")
    // }
      // console.log(
      //   "useeffect in character builder end storydata==>",
      //   storyData?.illustrationCharacter,
      //   "bookdata",
      //   bookData?.illustrationCharacter,
      //   "currenttPortrait n list",
      //   currentPortrait,
      //   currentPortraitList
      // );
  }, [storyData]);

  useEffect(() => {
    if (continueWithoutImage) {
      if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN &&
        characterOutlineByUser?.age &&
        characterOutlineByUser?.ageUnit &&
        characterOutlineByUser?.gender &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL &&
        characterOutlineByUser?.animalType &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL &&
        characterOutlineByUser?.character &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else setDisabledGenerateNewCharacter(true);
    } else {
      if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN &&
        characterOutlineByUser?.age &&
        characterOutlineByUser?.ageUnit &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL &&
        characterOutlineByUser?.animalType &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else if (
        characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL &&
        characterOutlineByUser?.character &&
        characterOutlineByUser?.mics
      )
        setDisabledGenerateNewCharacter(false);
      else setDisabledGenerateNewCharacter(true);
    }
  }, [characterOutlineByUser]);

  useEffect(() => {
    let currentCharacter = characterOutlineByUser?.characterType;
    //***** If upload picture reset form */
    if (selectedImage !== DUMMY_IMAGE) {
      if (currentCharacter === CHARACTER_TYPE_HUMAN) {
        setCharacterOutlineByUser({
          age: "",
          ageUnit: storyData?.ageUnit || CHARACTER_AGE_UNIT,
          mics: storyData?.mics || "",
          gender: "",
          animalType: storyData?.animalType || "",
          characterType: storyData?.characterType || CHARACTER_TYPE_HUMAN,
          character: storyData?.character || currentCharacter || CHARACTER_TYPE_HUMAN,
        }); //*/
      } else {
        setCharacterOutlineByUser({
          age: storyData?.age,
          ageUnit: storyData?.ageUnit,
          mics: storyData?.mics,
          gender: storyData?.gender,
          animalType: storyData?.animalType,
          characterType: storyData?.characterType,
          character: storyData?.character || currentCharacter,
        });
      }
    } else {
      //setCharacterOutlineByUser({ characterType: currentCharacter });
      setCharacterOutlineByUser({
        age: storyData?.age || "",
        ageUnit: storyData?.ageUnit || CHARACTER_AGE_UNIT,
        mics: storyData?.mics || "",
        gender: storyData?.gender,
        animalType: storyData?.animalType,
        characterType: storyData?.characterType || CHARACTER_TYPE_HUMAN,
        character: storyData?.character || currentCharacter || CHARACTER_TYPE_HUMAN,
      });
    }
  }, [selectedImage]);

  const CharacterSchema = Yup.object().shape({
    characterType: Yup.string().required("Character Type is required"),
    age:
      continueWithoutImage && characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN
        ? Yup.number()
            .required("Age is required")
            .min(1, "Age must be at least 1")
            .max(100, "Age cannot exceed 100")
        : Yup.number(),
    ageUnit:
      continueWithoutImage && characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN
        ? Yup.string().required("Age unit is required")
        : Yup.string(),
    gender:
      continueWithoutImage && characterOutlineByUser?.characterType === CHARACTER_TYPE_HUMAN
        ? Yup.string().required("Gender is required")
        : Yup.string(),
    mics:
      continueWithoutImage && characterOutlineByUser?.characterType !== CHARACTER_TYPE_HUMAN
        ? Yup.string().required("Character description is required")
        : Yup.string(),
    //for animal character type
    animalType:
      (characterOutlineByUser?.characterType != CHARACTER_TYPE_HUMAN && !continueWithoutImage) ||
      (continueWithoutImage && characterOutlineByUser?.characterType === CHARACTER_TYPE_ANIMAL)
        ? Yup.string().required("Animal Type is required")
        : Yup.string().nullable(),
    //for fiction character type
    character:
      (characterOutlineByUser?.characterType != CHARACTER_TYPE_HUMAN && !continueWithoutImage) ||
      (continueWithoutImage && characterOutlineByUser?.characterType === CHARACTER_TYPE_FICTIONAL)
        ? Yup.string().required("Character Name is required")
        : Yup.string(),
  });

  const validateField = async (name, value) => {
    try {
      await Yup.reach(CharacterSchema, name).validate(value);
      console.log("validateField", errors);
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      console.log("validateField catch", errors);
      setErrors({ ...errors, [name]: error.message });
    }
  };

  const startStepFour = async () => {
    if (storyData?.illustrationCharacter) {
      /**
       * Navigate to Generate New Illustration Component
       */

      if (storyData?.illustrationCharacter?.isPortraitDeleted === false) {
        await saveCharacterForImagePrompt(
          storyData?.illustrationCharacter?.bookId,
          currentPortrait,
          storyData?.illustrationCharacter?.pageIndex,
          characterOutlineByUser,
          storyData?.uploadedImage
        );
      }
    }
    setCurrentStoryStep(STORY_STEP_ILLUSTRATION_CREATE);

    setCurrentStep(3);
    setStoryData((prevData) => ({
      ...prevData,
      characterBuilding: false,
      newRefImageUploaded: false,
    }));
  };

  const handleImageChange = async (input) => {
    let file = input.target ? input.target.files[0] : input;
    if (!file) {
      console.error("No image selected");
      return;
    }
    setSelectedImage(file);
    setUploadedImageUrl(null);

    setCurrentPortraitList([]);
    setCurrentPortrait(null);
    setStoryData((prevData) => ({
      ...prevData,

      isDummyPic: false,
    }));
    setContinueWithoutImage(false);

    setStoryData((prev) => ({
      ...prev,
      illustrationCharacter: {
        ...prev.illustrationCharacter,
        isPortraitDeleted: false,
        newUploaded: true,
        pageData: {
          customCharacterId: null
        }
      },
    }));

    getCharactersMatchingLibrary(null);
    setCurrentPortraitList([]);
  };

  const getCharacterMatchingLibrary = () => {
    return library?.filter((character) => character.artStyle === selectedArtStyle?.id);
  };

  const getCharactersMatchingLibrary = (characterId) => {
    if (characterId) {
      return library?.filter((character) => character.artStyle === selectedArtStyle?.id && character.characterId === characterId)?.map((character) => character?.characterUrl);
    }
    return currentPortraitList;
  };

  const handleDeleteImage = async () => {
    setSelectedImage(DUMMY_IMAGE);
    setUploadedImageUrl(DUMMY_IMAGE);

    setCurrentPortrait(DUMMY_IMAGE);
    setPreviewImage(null);

    setStoryData((prevData) => ({
      ...prevData,
      isDummyPic: true,
      uploadedImage: null,
      age: 1,
      ageUnit: CHARACTER_AGE_UNIT,
      gender: "",
      animalType: null,
      characterType: CHARACTER_TYPE_HUMAN,
      mics: "",
    }));
    setContinueWithoutImage(true);
    setStoryData((prev) => ({
      ...prev,
      illustrationCharacter: {
        ...prev.illustrationCharacter,
        isPortraitDeleted: true,
        pageData: {
          customCharacterId: null
        }
      },
    }));

    getCharactersMatchingLibrary(null);
    setCurrentPortraitList([]);
  };

  const loadCharacterOutline = (outliner) => {
    const keys = Object.keys(outliner || {});
    keys?.map((key) => {
      setStoryData((prevState) => ({
        ...prevState,
        [key]: outliner[key],
      }));
    });
  };

  //section for training videosssssss
  const [showPopUp, setshowPopUp] = useState(true);

  // Show popup after a delay
  // useEffect(() => {
  //   const popupTimer = setTimeout(() => {
  //     setshowPopUp(true);
  //   }, 3000);

  //   return () => clearTimeout(popupTimer);
  // }, []);

  const onClose = async () => {
    try {
      await callAPI("user/updateUser", {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        settings: user?.settings,
        screentoUpdate: "screen2",
      });
    } catch (error) {
      console.log("error updating user training screen", error.message);
    } finally {
      setshowPopUp(false);
    }
  };

  // console.log("character builder", trainingVideos?.STORY_CHARCTER_BUILDER_VIDEO);

  // console.log("ccureeenttt potratlistt", currentPortraitList);
  return (
    <>
      {showPlanModal && (
        <Modal
          title="Plan Purchase"
          description="You can regenerate when using a purchased plan, why not first see your character brought to life in a story?"
          doProcess={() => navigateTo("/myPlans")}
          showCloseButton={false}
          showPromoFreeBook={true}
          characterRegenerate={true}
          continueBook={startStepFour}
          submitButtonTitle="Purchase Plan"
        />
      )}
      {showRelaxModeModal && (
        <Modal
          // title="High Speed Image Renewal Alert"
          description="Hey, we’ve noticed you’re enjoying editing your book! To keep things running smoothly, we’re switching to relaxed mode, which means image generation might take a little longer. Take your time and keep creating!"
          doProcess={() => navigateTo("/ImageTokens")}
          // submitButtonTitle="re-store image renewal speed"
          // showCloseButtonFunction={showCloseButtonRelaxModalFunction}
          showCloseButton={false}
          showFreeBookViaRelaxMode={true}
          showSubmitButton={false}
        />
      )}
      {(!showCharacterLibrary && storyNewCharacter) ||
      !(getCharacterMatchingLibrary()?.length > 0) ||
      (storyData?.characterBuilding === true && storyData.showLibrary === false) ||
      (currentPortrait && currentPortrait !== DUMMY_IMAGE && storyData.showLibrary === false) ? (
        <div className="animate__animated animate__slideInRight p-4">
          {showPopUp === true && user?.showTrainingPopup["screen2"] === true && (
            <TrainingPopup
              videoUrl={currentStep === 2 ? trainingVideos?.STORY_CHARCTER_BUILDER_VIDEO : null}
              onClose={onClose}
              showScreenName={"screen2"}
              user={user}
              focus={showPopUp}
            />
          )}
          <div className="w-full flex justify-between flex-wrap gap-2 items-center">
            <div className=" flex items-center align-end">
              <h2 className="text-left text-xl md:text-4xl font-bold text-storyblue">
                Build My Character Illustrations
              </h2>
              <div className="mt-2">
                <Guide />
                {/* <Guide
                to={currentStep === 2 ? trainingVideos?.STORY_CHARCTER_BUILDER_VIDEO : null}
               
                screen={"screen2"}
                user={user}
                /> */}
              </div>
            </div>

            <div className="flex gap-2 flex-wrap md:flex-nowrap">
              {getCharacterMatchingLibrary()?.length > 0 && (
                <button
                  onClick={() => {
                    setShowCharacterLibrary(true);
                    setStoryData((prevData) => ({
                      ...prevData,
                      showLibrary: true,
                    }));
                    getCharacterLibary(user?.email, loaders, setLoaders, setLibrary);
                  }}
                  className=" px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11 "
                  disabled={loaders?.characterGenerating || loaders?.characterRegenerate}
                >
                  Character Library
                </button>
              )}

              {(!draftId || storyData?.illustrationCharacter) && (
                <button
                  className=" px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11 "
                  onClick={() => {
                    // console.log("illustrationCharacter", illustrationCharacter, "draftID", draftId);

                    const isEditMode = localStorage.getItem(LOCAL_STORAGE_EDIT_MODE);
                    const setEditMode = isEditMode ? true : false;

                    if (!setEditMode && !storyData?.illustrationCharacter?.pageIndex) {
                      setCurrentStep(currentStep - 1);
                      setCurrentStoryStep(STORY_STEP_BASIC_INFO);
                    } else if (storyData?.illustrationCharacter) {
                      setCurrentStep(3);
                      setCurrentStoryStep(STORY_STEP_ILLUSTRATION_CREATE);
                    }
                  }}
                  disabled={
                    // storyData?.characterBuilding ||
                    imageUploading || loaders?.characterGenerating || loaders?.characterRegenerate
                  }
                >
                  Back
                </button>
              )}

              {/* {draftId && (
                <Button
                  title={"Close"}
                  onclick={() => resetStory()}
                  className={" px-4 bg-storyblue text-white font-bold rounded-md text-sm h-11"}
                />
              )} */}
            </div>
          </div>
          {selectedImage || currentPortrait ? (
            <p className="my-4 md:my-6 text-left text-sm text-storyblue w-full lg:w-[70rem]">
              {loaders?.characterGenerating || loaders?.characterRegenerate || bookData?.isImageProcessing
                ? ""
                : `When you describe your character make sure to use simple descriptions.`}
              {/* Each time you choose
              "Generate New Character," you will be presented with four potential images. Select the one that
              you like by clicking on it or keep selecting "Generate New Character" until you find an image
              that suits your vision. Once you've identified your main character, simply select that image (it
              will be displayed in the large view window) and proceed to "Build My Story. */}
            </p>
          ) : (
            <p className="my-4 text-left text-sm text-storyblue w-full lg:w-[70rem]">
              Let’s Build My Character. Please upload a headshot with no other people or objects.
            </p>
          )}

          {showFileInput &&
            currentStep === 2 &&
            (loaders?.characterGenerating || loaders?.characterRegenerate || bookData?.isImageProcessing ? (
              <div>
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <img className="w-24 h-24 mb-3" src="/loading.gif" alt="Loading" />
                  <p className="mb-2 text-sm text-storyblue dark:text-gray-400">
                    <span className="font-semibold text-storygreen">{`Generating Character ${
                      characterGenerationProgress || 10
                    } %`}</span>
                    <p>Character Builder will take a few minutes</p>
                    <p className="text-storygreen mt-8">
                      All characters created will be saved in your character library.<br></br> As your
                      character is being created, you'll have the choice of four distinct options.
                    </p>
                    <p className="text-storygreen">
                      If you wish to expand the range of choices, you can adjust any of the input parameters
                      and then select "Regenerate Character",<br></br> or you can keep the options unchanged
                      and select "Regenerate Character".
                    </p>
                  </p>
                </div>
              </div>
            ) : (
              <div className=" w-full  flex flex-col gap-2 ">
                <div className=" w-full flex flex-col justify-around lg:flex-row gap-4 mt-2 rounded-[1.4rem]">
                  {!selectedImage && (!currentPortrait || currentPortrait === DUMMY_IMAGE) && (
                    <div className="w-full lg:w-[46%] mt-10 flex flex-col justify-center items-center gap-2">
                      <Uploader
                        onFileChange={handleImageChange}
                        setPreviewImage={setPreviewImage}
                        setShouldCoverPortrait={setShouldCoverPortrait}
                      />
                      <p>Or</p>
                      <Button
                        title={"Continue Without Uploading Photo"}
                        className=" px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11 "
                        onclick={() => {
                          setSelectedImage("./Image holder Icon.png");
                          setContinueWithoutImage(true);
                        }}
                        tooltip="I will describe My Character"
                      />
                    </div>
                  )}

                  {(selectedImage || (currentPortrait && currentPortrait !== DUMMY_IMAGE)) && (
                    <>
                      <div className="w-full h-full rounded-md lg:w-[46%]">
                        {(uploadedImageUrl || continueWithoutImage || currentPortrait) &&
                        !imageUploading &&
                        !loaders.characterGenerating &&
                        !loaders.characterRegenerate &&
                        (currentPortraitList?.length > 0 || currentPortrait) ? (
                          <div className="text-center">
                            {currentPortrait && (
                              <div className="relative">
                                {/* {currentPortrait === null  && ( */}
                                {/* <div className="mr-0 md:mr-[3.2rem] lg:mr-2 absolute top-4 right-[3rem] md:right-[10rem] lg:right-16 w-10 h-10 bg-white rounded-md"> */}
                                <div className="mr-0 lg:mr-2 absolute top-4 right-[1rem] md:right-[10rem] lg:right-24 w-10 h-10 bg-white rounded-md">
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className={`mt-2 text-[#EF4444] text-2xl cursor-pointer`}
                                    onClick={() => {
                                      handleDeleteImage();
                                    }}
                                  />
                                </div>
                                {/* )} */}
                                <img
                                  src={currentPortrait || storyData?.uploadedImage}
                                  className={`h-[20rem] w-[18rem] md:h-[25rem] md:w-[25rem] mx-auto rounded-[1.5rem] mb-4 ${
                                    shouldCoverPortrait ? "object-cover" : ""
                                  }`}
                                />
                                <h1 className="w-[19rem] mx-auto md:w-[24rem] text-xs text-red-500 text-left">
                                  AI-generated content may not always be 100% accurate or free from errors.
                                  {/* {currentPortrait} */}
                                </h1>
                              </div>
                            )}
                            {currentPortraitList
                                && (
                              <div className="flex gap-4 md:gap-6 justify-center">
                                { getCharactersMatchingLibrary(storyData?.illustrationCharacter?.pageData?.customCharacterId)
                                    ?.map((img) => {
                                  const imageClass =
                                    currentPortrait === img ? "border-2 border-storyblue" : "opacity-50";
                                  return (
                                    <img
                                      src={img}
                                      className={`h-14 md:h-20 w-14  md:w-20 rounded-md my-4 cursor-pointer ${imageClass}`}
                                      onClick={() => {
                                        setCurrentPortrait(img);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex flex-col items-center gap-2">
                            <div className="relative">
                              {previewImage && (
                                <div className="absolute top-4 right-6 w-10 h-10 bg-white rounded-md">
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    className={`mt-2 text-[#EF4444] text-2xl cursor-pointer`}
                                    onClick={() => {
                                      handleDeleteImage();
                                    }}
                                  />
                                </div>
                              )}
                              <img
                                // src={!previewImage ? storyPreview : previewImage}
                                src={
                                  previewImage ||
                                  storyData?.illustrationCharacter?.uploadedImage ||
                                  storyData?.uploadedImage
                                }
                                alt="Selected"
                                className="w-[20rem] h-[20rem] md:w-[25rem] md:h-[25rem] rounded-[1.5rem] object-cover"
                              />
                            </div>

                            <h1 className="w-[19rem] md:w-[24rem] text-xs text-red-500 text-left mx-2">
                              AI-generated content may not always be 100% accurate or free from errors.
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="w-full lg:w-[46%]">
                        {/* <p className="h-10 text-xs text-storyblue text-center">
                          In addition we have provided prompts for you to help define your character.
                        </p> */}
                        <CharacterDescriptionInputs
                          setCharacterOutlineByUser={setCharacterOutlineByUser}
                          characterOutlineByUser={characterOutlineByUser}
                          handleImageChange={handleImageChange}
                          setPreviewImage={setPreviewImage}
                          previewImage={previewImage}
                          selectedImage={selectedImage}
                          continueWithoutImage={continueWithoutImage}
                          errors={errors}
                          setErrors={setErrors}
                          validateField={validateField}
                          handleDeleteImage={handleDeleteImage}
                          currentPortrait={currentPortrait}
                          setCurrentPortrait={setCurrentPortrait}
                          setShouldCoverPortrait={setShouldCoverPortrait}
                        />

                        <div className="flex md:justify-end gap-4 flex-wrap my-2 w-[20rem] md:w-[25rem] mx-auto pr-4">
                          {!imageUploading &&
                            !loaders.characterGenerating &&
                            !loaders.characterRegenerate &&
                            selectedImage &&
                            currentPortraitList?.length === 0 &&
                            !bookData?.characterPortrait && (
                              <>
                                <Button
                                  title="Generate New Character"
                                  // tooltip={`Generate New Character Will Use ${DEDUCT_CREDITS_PER_IMAGE} Image Credits`}
                                  // loader={loader}
                                  onclick={async () => {
                                    // if (parseInt(userCreditBalance) >= parseInt(DEDUCT_CREDITS_PER_IMAGE)) {
                                    // if (
                                    //   parseInt(bookData?.creditsPerBookLimit) >=
                                    //   parseInt(DEDUCT_CREDITS_PER_IMAGE)
                                    // )

                                    if (
                                      (parseInt(storyData?.creditsPerBookLimit) >=
                                        parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
                                        parseInt(bookData?.creditsPerBookLimit) >=
                                          parseInt(DEDUCT_CREDITS_PER_IMAGE)) ||
                                      bookData?.modeCharacterGeneration === MID_JOURNEY_MODE_RELAX
                                    ) {
                                      const creditsToDeduct =
                                        bookData?.modeCharacterGeneration === MID_JOURNEY_MODE_RELAX
                                          ? 0
                                          : parseInt(DEDUCT_CREDITS_PER_IMAGE);

                                      //updated code
                                      // console.log("with image character called");
                                      setStoryData({
                                        ...storyData,
                                        creditsPerBookLimit:
                                          parseInt(storyData?.creditsPerBookLimit) - creditsToDeduct,
                                      });

                                      //old code
                                      // console.log("with image character called");
                                      // setStoryData({
                                      //   ...storyData,
                                      //   creditsPerBookLimit:
                                      //     parseInt(storyData?.creditsPerBookLimit) - DEDUCT_CREDITS_PER_IMAGE,
                                      // });

                                      //console.log("selectedArtStyle", selectedArtStyle);
                                      if (continueWithoutImage) {
                                        try {
                                          // let res = await CharacterSchema.validate(characterOutlineByUser, {
                                          //   abortEarly: false,
                                          // });
                                          handleSubmitStepTwo(
                                            bookId,
                                            selectedImage,
                                            selectedArtStyle,
                                            characterOutlineByUser,
                                            setLoaders,
                                            loaders,
                                            setCharacterDescription,
                                            setUploadedImageUrl,
                                            setImageUploading,
                                            setStartInterval,
                                            setCharacterOutlineByUser,
                                            setCurrentPortrait,
                                            setCurrentPortraitList,
                                            continueWithoutImage,
                                            setStoryData,
                                            // illustrationCharacter,
                                            storyData
                                            // setIllustrationCharacter
                                          );
                                        } catch (validationErrors) {
                                          const newErrors = {};
                                          validationErrors.inner.forEach((error) => {
                                            newErrors[error.path] = error.message;
                                            console.log("newErrors", newErrors);
                                          });
                                          setErrors(newErrors);
                                        }
                                      } else {
                                        handleSubmitStepTwo(
                                          bookId,
                                          selectedImage,
                                          selectedArtStyle,
                                          characterOutlineByUser,
                                          setLoaders,
                                          loaders,
                                          setCharacterDescription,
                                          setUploadedImageUrl,
                                          setImageUploading,
                                          setStartInterval,
                                          setCharacterOutlineByUser,
                                          setCurrentPortrait,
                                          setCurrentPortraitList,
                                          continueWithoutImage,
                                          setStoryData,
                                          // illustrationCharacter,
                                          storyData
                                          // setIllustrationCharacter
                                        );
                                      }
                                    } else {
                                      // Toast("Additional Image Credits Needed", true);
                                      // navigateTo("/ImageTokens");
                                      setShowRelaxModeModal(true);
                                    }
                                  }}
                                  disabled={disabledGenerateNewCharacter}
                                  className={`${
                                    disabledGenerateNewCharacter && "opacity-50"
                                  } submit-button px-4 bg-storyblue text-white font-bold rounded-md text-sm  h-11 mb-2`}
                                />
                              </>
                            )}

                          {(selectedImage || continueWithoutImage || currentPortrait) &&
                            !imageUploading &&
                            !loaders.characterGenerating &&
                            !loaders.characterRegenerate &&
                            (bookData?.currentCharacterPortraitList?.length > 0 ||
                              bookData?.characterPortrait) && (
                              // <div className="w-[20rem] md:w-[25rem] flex justify-center md:justify-end gap-2 mx-auto flex-wrap md:flex-nowrap">
                              <div className="w-[18rem] md:w-[25rem] flex justify-center md:justify-end gap-2 mx-0 md:mx-auto flex-wrap md:flex-nowrap">
                                {/* {user?.plan?.name !== PROMO_PLAN && ( */}
                                <button
                                  // title="Uses Same Uploaded Image With Option To Modify Prompts"
                                  title={`This Request Will Use ${DEDUCT_CREDITS_PER_IMAGE} Image Credits`}
                                  disabled={disabledGenerateNewCharacter}
                                  onClick={async () => {
                                    // if (parseInt(userCreditBalance) >= DEDUCT_CREDITS_PER_IMAGE) {
                                    // if (parseInt(bookData?.creditsPerBookLimit) >= DEDUCT_CREDITS_PER_IMAGE)
                                    console.log(
                                      "regenerate clicked ",
                                      user?.plan?.name,
                                      storyData?.creditsPerBookLimit,
                                      bookData?.creditsPerBookLimit
                                    );
                                    if (
                                      user?.plan?.name === PROMO_PLAN &&
                                      parseInt(storyData?.creditsPerBookLimit) <=
                                        parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
                                      parseInt(bookData?.creditsPerBookLimit) <=
                                        parseInt(DEDUCT_CREDITS_PER_IMAGE)
                                    ) {
                                      setShowPlanModal(true);
                                    } else {
                                      if (
                                        (parseInt(storyData?.creditsPerBookLimit) >=
                                          parseInt(DEDUCT_CREDITS_PER_IMAGE) &&
                                          parseInt(bookData?.creditsPerBookLimit) >=
                                            parseInt(DEDUCT_CREDITS_PER_IMAGE)) ||
                                        bookData?.modeIllustrationGeneration === MID_JOURNEY_MODE_RELAX
                                      ) {
                                        //Need to be change
                                        const creditsToDeduct =
                                          bookData?.modeIllustrationGeneration === MID_JOURNEY_MODE_RELAX
                                            ? 0
                                            : parseInt(DEDUCT_CREDITS_PER_IMAGE);

                                        // updated code
                                        setStoryData({
                                          ...storyData,
                                          creditsPerBookLimit:
                                            parseInt(storyData?.creditsPerBookLimit) - creditsToDeduct,
                                        });

                                        // old code
                                        //  setStoryData({
                                        //    ...storyData,
                                        //    creditsPerBookLimit:
                                        //      parseInt(storyData?.creditsPerBookLimit) -
                                        //      DEDUCT_CREDITS_PER_IMAGE,
                                        //  });

                                        handleSubmitStepTwo(
                                          bookId,
                                          selectedImage,
                                          selectedArtStyle,
                                          characterOutlineByUser,
                                          setLoaders,
                                          loaders,
                                          setCharacterDescription,
                                          setUploadedImageUrl,
                                          setImageUploading,
                                          setStartInterval,
                                          setCharacterOutlineByUser,
                                          setCurrentPortrait,
                                          setCurrentPortraitList,
                                          continueWithoutImage,
                                          setStoryData,
                                          // illustrationCharacter,
                                          storyData
                                          // setIllustrationCharacter
                                        );
                                      } else {
                                        Toast("Additional Image Credits Needed", true);
                                        // navigateTo("/ImageTokens");
                                      }
                                    }
                                  }}
                                  className={`w-full submit-button px-4 ${
                                    disabledGenerateNewCharacter && "opacity-50"
                                  }  bg-storygreen
                                   text-white font-bold rounded-md text-sm h-11 mb-2`}
                                >
                                  Regenerate Character
                                </button>

                                <button
                                  onClick={startStepFour}
                                  className="w-full submit-button h-11 bg-storyblue text-white font-bold text-sm rounded-md whitespace-nowrap  p-2"
                                >
                                  Continue
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        library &&
        library.filter((character) => character.artStyle === selectedArtStyle?.id).length > 0 && (
          <CharacterLibrary
            getCharacterLibary={getCharacterLibary}
            loaders={loaders}
            library={library}
            setLibrary={setLibrary}
            setUploadedImageUrl={setUploadedImageUrl}
            setShowCharacterLibrary={setShowCharacterLibrary}
            currentPortrait={currentPortrait}
            setCurrentPortrait={setCurrentPortrait}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setLoaders={setLoaders}
            setStateData={setStateData}
            stateData={stateData}
            artStyle={artStyle}
            startStepFour={startStepFour}
            // setimageCharacterPrompt={setimageCharacterPrompt}
            // imageCharacterPrompt={imageCharacterPrompt}
            // setSelectedImage={setSelectedImage}
            resetCharacter={resetCharacter}
            selectedArtStyle={selectedArtStyle}
          />
        )
      )}
    </>
  );
};

export default StepCharacterBuilder;