import React, {useEffect, useState} from "react";
import {
  RouterProvider
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./App.css";
import {useUser} from "./context/userContext";
import RouterMain from "./routes";
import Modal from "./components/Common/Modal";
import TermsAndConditions from "./components/Common/TermsAndConditions";


const {
  REACT_APP_LOAD_COOKIE_EYES,
  REACT_APP_FIREBASE_MODE,
  REACT_APP_STRIPE_PUB_KEY_TEST,
  REACT_APP_STRIPE_PUB_KEY_LIVE,
  REACT_APP_CLARITY_ID,
} = process.env;


function App() {
  const { user, setUser } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    //Chat Bot
    const loadCrisp = () => {
      window.$crisp = [];
      // window.CRISP_WEBSITE_ID = "4f87b416-6723-4b45-a517-4ee62092d378";
      window.CRISP_WEBSITE_ID = "43f025bd-04e0-4021-9a1d-531309a357f5";

      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.async = 1;
      document.getElementsByTagName("head")[0].appendChild(script);
    };

    // Apply custom classes to adjust chat widget position on smaller screens
    const style = document.createElement("style");
    style.innerHTML = `
      @media (max-width: 1024px) {
        .crisp-client .crisp-client {
          right: 0 !important;
          bottom: 0 !important;
          max-width: calc(100% - 20px) !important; /* Ensure it stays within the viewport */
          overflow: hidden !important;
        }
      }
    `;

    const loadCookieEyes = () => {
      const script = document.createElement("script");
      script.src = "https://cdn-cookieyes.com/client_data/29131085552c2dc4ec09d641/script.js";
      script.async = 1;
      document.getElementsByTagName("head")[0].appendChild(script);
    };

    const loadClarity = (clarityKey) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = 1;
      script.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityKey}");
      `;
      document.getElementsByTagName("head")[0].appendChild(script);
    };
    
    // const loadgoAffPro = ()=>{

    //    const script = document.createElement("script");
    //    script.src = "https://api.goaffpro.com/loader.js?shop=w6dmdvcn04";
    //    script.async = 1;
    //    document.getElementsByTagName("head")[0].appendChild(script);
    // }

    const clarityKey = REACT_APP_FIREBASE_MODE !== "development"
      ? "pmloobrzm2"
      : REACT_APP_CLARITY_ID;
      
    loadClarity(clarityKey);

    loadCrisp(); //*/
    if (REACT_APP_LOAD_COOKIE_EYES === "true") loadCookieEyes();
    // loadgoAffPro();
    // init("w6dmdvcn04");
  }, []);

  const stripePublicKey = REACT_APP_FIREBASE_MODE !== "development"
      ? REACT_APP_STRIPE_PUB_KEY_LIVE
      : REACT_APP_STRIPE_PUB_KEY_TEST;

  // Load the Stripe promise
  const stripePromise = loadStripe(stripePublicKey);

  const handleGlobalClick = (e) => {
    setUser({ ...user, closeTermsAndConditions: false });
  }

  useEffect((data) => {
    // Show modal if terms are not accepted
    if (!user?.email || !!user?.uid && !!user?.auth) {
      return;
    }
    if (!user?.closeTermsAndConditions) {
      setTimeout(() => setIsModalVisible(!user?.acceptedTermsAndConditions), 1000);
      return;
    }

    setIsModalVisible(false);
    setTimeout(() => document.addEventListener('click', handleGlobalClick));

    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [user]);

  return (
    <>
        <Elements stripe={stripePromise}>
          {isModalVisible && (
              <Modal
                  title={`This is a BETA. Please agree to the terms and conditions of \n the Non-Disclosure Agreement (NDA)`}
                  className={'terms-and-conditions-modal'}
                  showSubmitButton={false}
                  showCloseButton={false}
              >
                <TermsAndConditions></TermsAndConditions>
              </Modal>
          )}
          <div className="App bg-white min-h-screen">
            <RouterProvider router={RouterMain}>
            </RouterProvider>
          </div>
          <Toaster position="bottom-left"/>
        </Elements>
    </>
  );


}

export default App;


