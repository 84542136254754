import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HTTP_STATUS_200, LOCAL_STORAGE_TOKEN } from "../../helper/Constant";

import TableHeader from "../../components/Common/TableHeader";
import { NoRecordFound } from "../../components/Common/NoRecordFound";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import SubmitButton from "../../components/Common/SubmitButton";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilm } from "@fortawesome/free-solid-svg-icons";

// import Loader from '../../components/Common/Loader';

const TrainingVideos = () => {
  const [loader, setLoader] = useState(true);
  const [disable, setDisable] = useState(false);
  const [TrainingVideos, setTrainingVideos] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(LOCAL_STORAGE_TOKEN)) {
      navigate("/admin");
    }
  });

  const getTrainingVideos = async () => {
    try {
      setLoader(true);
      const data = await callAPI(`admin/getTrainingVideos`);
      console.log("liksss,", data?.links);
     if(data?.links) setTrainingVideos(data?.links) 
      else Toast("someThing Went Wrong!", true);
      //   setTotalPages(data?.totalPages);
      setLoader(false);
    } catch {
      setLoader(false);
      Toast("someThing Went Wrong!", true);
    }
  };

  useEffect(() => {
    getTrainingVideos();
  }, []);

  function formatLabel(label) {
    return label
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleInputChange = (key, value) => {
    setTrainingVideos((prev) => ({
      ...prev,
      // links: {
      // ...prev.links,
      [key]: value,
      // },
    }));
  };
  const goBack = () => {
    navigate(-1);
  };

  const validateTrainingVideos = async (trainingVideos) => {
  const YOUTUBE_REGEX = /^https:\/\/www\.youtube\.com\/embed\/[\w-]+(\?.*)?$/;

  const allowedKeys = [
    "STORY_CHARCTER_BUILDER_VIDEO",
    "STORY_EDITOR_VIDEO",
    "STORY_INTRO_VIDEO",
    "STORY_PURCHASE_AND_VIEW_VIDEO",
  ];

    // Check if all keys exist and all URLs are valid
    for (const [key, url] of Object.entries(trainingVideos)) {
      if (!allowedKeys.includes(key)) {
        return { valid: false, message: `Invalid key: ${key}` };
      }
      if (!YOUTUBE_REGEX.test(url)) {
        return { valid: false, message: `Invalid YouTube URL for key: ${key}` };
      }
    }

    return { valid: true, message: "Validation passed" };
  };

  const handleSubmit = async () => {
    try {
      setDisable(true);
      setLoader(true);

      const validationResult = await validateTrainingVideos(TrainingVideos);

      if (!validationResult.valid) {
        Toast("Valid Youtube links required!", true);
      } else {
        const response = await callAPI(`admin/updateTrainingVideos`, { TrainingVideos });
        console.log("response", response);
        if (response?.status === HTTP_STATUS_200) {
          Toast("Training Videos Updated Successfully!", false);
        } else {
          Toast("Something Went Wrong!", true);
        }
      }
    } catch (error) {
      Toast("Something Went Wrong!", true);
    } finally {
      setDisable(false);
      setLoader(false);
    }
  };

  return (
    <div>
      <div className="">
        <p className="text-left pl-4 ">
          For smooth integration of training videos for users, only add "embed" links from youtube
        </p>
        {loader ? (
          <div className="w-full  h-80 flex justify-center items-center">
            <img className="w-12 h-12" src="/loading.gif" alt="Loading" />
          </div>
        ) : (
          <>
            <form
              id="training-videos-form"
              name="training-videos-form"
              className="mt-2 text-start "
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="h-[17rem] md:h-[34rem] ">
                <div className="flex flex-col gap-1">
                  {TrainingVideos && Object.keys(TrainingVideos).length > 0 ? (
                    Object.entries(TrainingVideos).map(([key, video], index) => (
                      <div
                        key={key}
                        className={`p-4
                        `}
                      >
                        <div
                        // className="grid grid-cols-1 gap-2"
                        >
                          <label
                            htmlFor={`${key}-screen`}
                            className="block text-base font-bold text-gray-700"
                          >
                            {formatLabel(key)}
                          </label>

                          <div className="relative flex items-center">
                            <input
                              id={`${key}-link`}
                              type="text"
                              value={video || ""}
                              onChange={(e) => handleInputChange(key, e.target.value)}
                              placeholder="Enter video link"
                              className="mt-1 h-11 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm md:text-base"
                            />
                            {video && (
                              <a
                                href={video}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                              >
                                <FontAwesomeIcon
                                  icon={faFilm}
                                  className="text-2xl text-storylightgreen hover:text-green-600 transition-colors duration-200"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoRecordFound />
                  )}
                  <SubmitButton
                    title="Save"
                    loader={loader}
                    disabled={disable}
                    size={`${disable ? "opacity-50" : ""} w-full mx-4`}
                    cancelOperation={goBack}
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default TrainingVideos;
