import { faRegistered } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Admin/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../context/userContext";
import { useEffect } from "react";
import { callAPI } from "../../services/apiService";
import { getCurrentYear, goToTopOfPage } from "../../helper/helper";
const FrontCoverPages = ({
  bookData,
  setShowBookCover,
  showBookCover,
  editCover = false,
  selectedCoverUrl,
  displayBookPages,
  mode,
  setStateData,
  stateData,
}) => {
  // console.log("front page cover ==== ", selectedCoverUrl, bookData?.coverURL);
  const { user } = useUser();
  // console.log("first,", user);
  // const [coverURL,setCoverURL]=useEffect(null);

  const updateCover = async () => {
    await callAPI("api/update-cover", {
      bookId: bookData?.bookId,
      coverURL: bookData?.pages?.page1?.imageURLwebp,
    });
    displayBookPages();
  };

  useEffect(() => {
    goToTopOfPage();
  }, []);

  useEffect(() => {
    if (bookData?.pages?.page1?.imageURLwebp && !bookData?.coverURL) updateCover();
  }, [bookData?.pages?.page1?.imageURLwebp, bookData?.coverURL]);

  return (
    <div
      className={` ${mode === "Page" ? " " : "grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4 "}`}

      // className="flex flex-col gap-5"
    >
      {/* Book cover */}
      <div
        className={` flex flex-col justify-center items-start gap-[1rem] lg:gap-2 mx-auto rounded xs:flex-wrap ${
          mode === "Grid" ? " md:flex-row" : "flex-col "
        }  py-4 mx-0 md:mx-2 `}
      >
        <div
          className={` flex flex-col items-center gap-[1rem] lg:gap-2 ${
            mode === "Grid" ? "mx-0 lg:mx-auto" : "ml-[2.5rem] mr-[0.5rem] lg:mx-auto"
          }`}
        >
          <div
            className={`rounded-lg ${
              mode === "Page" ? "h-[350px] w-[350px] md:w-[600px] md:h-[600px]" : " h-[18rem] w-[18rem]"
            }    flex justify-center items-center relative`}
          >
            <div
              className="absolute h-full w-full grayscale rounded"
              style={{
                backgroundImage: `url('${bookData?.coverURL ? bookData?.coverURL : "/texture.png"}')`,
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className={` ${
                mode === "Page"
                  ? "h-[320px] w-[270px] pt-[1rem] md:w-[460px] md:h-[530px] md:pt-[2rem]"
                  : "h-[16rem] w-[14rem] pt-[1rem]"
              }    rounded flex justify-center`}
              style={{
                backgroundImage: `url('${"/texture.png"}')`,
                backgroundSize: "cover",
                zIndex: 1,
              }}
            >
              <div
                className={` ${
                  mode === "Page" ? "w-[14rem] h-[14rem] md:w-[400px] md:h-[400px]" : "h-[12rem] w-[12rem]"
                }  flex justify-center items-center relative rounded`}
                style={{
                  backgroundImage: `url('${bookData?.coverURL}')`,
                  backgroundSize:
                    mode === "Grid"
                      ? "288px"
                      : mode === "Page" && window.innerWidth < 768
                      ? "350px"
                      : "600px",
                  backgroundPosition: mode === "Grid" ? "50% 33.4%" : "50% 33.4%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  className={` ${
                    mode === "Page"
                      ? "top-[9.5rem] sm:gap-2 sm:top-[9.5rem]  md:top-[15.5rem]"
                      : "top-[122px] "
                  } relative flex flex-col items-center `}
                >
                  <h1
                    className={` ${
                      mode === "Page" ? "text-md  md:text-2xl" : "leading-[15px] "
                    }  flex justify-center items-center text-center font-yuji-boku`}
                  >
                    {bookData?.title}
                  </h1>
                  <h1
                    className={` ${
                      mode === "Page" ? " text-[10px] md:text-lg " : " text-[10px] "
                    } font-poppins text-[10px] text-gray-600 mb-1`}
                  >
                    by {user?.firstName} {user?.lastName}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {editCover && (
            <button
              className="bg-storygreen flex  text-white font-sans rounded-md p-2 text-xs "
              onClick={() => {
                setShowBookCover(!showBookCover);
                setStateData({ ...stateData, showDownloadPDF: false });
              }}
            >
              Edit Cover
            </button>
          )}
        </div>
        <div
          className={` ${
            mode === "Grid" ? "h-72 w-72" : "w-[350px] h-[350px] md:w-[600px] md:h-[600px]"
          } flex justify-center items-center rounded-lg test ${
            mode === "Grid" ? "" : "ml-[2.5rem] lg:mx-auto"
          } bg-white`}
        >
          <p className={` ${mode === "Grid" ? "m-4" : "m-12 md:m-40"} m-4 text-gray-500 text-base`}>
            This page is Intentionally left blank
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mx-0 lg:mx-2">
        {mode === "Page" ? (
          <>
            <div
              className={` h-[350px] w-[350px]  md:w-[600px] md:h-[600px] flex flex-col rounded-lg mx-auto py-3 bg-gray-200 ${
                mode === "Grid" ? "" : "mb-4 ml-[3rem] mt-[1rem] lg:mx-auto lg:mt-0"
              } `}
              style={{
                backgroundImage: `url("/paper.jpg")`,
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col justify-center items-center p-5 md:px-16 md:py-8 gap-16 md:gap-44">
                <h3 className="text-center text-lg md:text-2xl bold font-yuji-boku">{bookData?.title}</h3>
                <div>
                  <p className="text-xs md:text-base text-justify font-poppins mb-2 md:mb-8">
                    All rights reserved. No part of the publication may be reproduced, stored in or introduced
                    into a retrieval system, or trasmitted, in any form, or by any means (electronic,
                    mechanica, photocopying, recording or otherwise) without the prior written permission of
                    the author.
                  </p>
                  <p className="text-xs md:text-base text-justify font-poppins">
                    Any person who does any unauthorized act in relation to this publication may be liable to
                    criminal prosecution ad civil claims for damages. Text and illustrations copyright &#169;{" "}
                    {user?.firstName} {user?.lastName} {getCurrentYear()} Moral rights asserted.
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col   items-center gap-1">
                <div className="flex items-baseline gap-1">
                  <img className="h-3 md:h-6 w-auto" src={Logo} alt="Build My Story" />
                </div>
              </div>
            </div>
            {/* branding */}
            <div
              className={` h-[350px] w-[350px]  md:w-[600px] md:h-[600px] flex flex-col rounded-lg ml-[3.5rem] mr-[.5rem] lg:mx-auto  bg-gray-200 ${
                mode === "Grid" ? "" : "mb-4 "
              } `}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url('paper.jpg')`,
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Logo} alt="logo" style={{ height: "4rem", margin: "auto" }} />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col md:flex md:flex-row md:justify-center gap-[1rem] lg:gap-4 p-0 md:p-4 rounded  xs:flex-wrap ">
            <div
              className=" h-72 w-72 flex flex-col justify-around rounded-lg mx-0 mx-auto sm:mx-0 lg:mx-auto py-3 bg-gray-200"
              style={{
                backgroundImage: `url("/paper.jpg")`,
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col justify-center items-center pt-4 pb-2 px-5 gap-8">
                <h3 className="text-center text-lg bold font-yuji-boku">{bookData?.title}</h3>
                <div className=" ">
                  <p className="leading-[12px] text-[10px] text-justify font-poppins mb-1">
                    All rights reserved. No part of the publication may be reproduced, stored in or introduced
                    into a retrieval system, or trasmitted, in any form, or by any means (electronic,
                    mechanica, photocopying, recording or otherwise) without the prior written permission of
                    the author.
                  </p>
                  <p className="leading-[12px] text-[10px] text-justify  font-poppins">
                    Any person who does any unauthorized act in relation to this publication may be liable to
                    criminal prosecution ad civil claims for damages. Text and illustrations copyright &#169;{" "}
                    {user?.firstName} {user?.lastName} {getCurrentYear()} Moral rights asserted.
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col items-center gap-1">
                <div className="flex items-baseline gap-1">
                  <img className="h-3 md:h-6 w-auto" src={Logo} alt="Build My Story" />
                </div>
              </div>
            </div>
            {/* Company Page */}
            <div
              className="h-72 w-72 flex flex-col justify-center rounded-lg mx-0 mx-auto sm:mx-0 lg:mx-auto py-3 bg-gray-200"
              style={{
                backgroundImage: `url("/paper.jpg")`,
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col justify-center items-center p-5 gap-2 "></div>
              <div className="w-full flex flex-col items-center gap-1">
                <div className="flex items-baseline gap-1">
                  <img className="h-4 md:h-8 w-auto" src={Logo} alt="Build My Story" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FrontCoverPages;
