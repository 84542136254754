import React, { useEffect, useState } from "react";
import {
  ORDER_TYPE_BOOK_PURCHASE,
  ORDER_TYPE_CREDITS_PURCHASE,
  ORDER_TYPE_SUBSCRIPTION_PURCHASE,
} from "../../helper/Constant";
import { useUser } from "../../context/userContext";
import { convertTimestampToDateTime } from "../../helper/helper";

const PurchaseConfirmation = ({ onClose, order }) => {
  const { user } = useUser();
  const [bookPrice, setBookPrice] = useState(12.95);
  const [subTotal, setSubTotal] = useState(0);
  const [description, setDescription] = useState({
    title: "",
    titleSummary: "",
    processDate: "",
    summaryDetail: {
      row1_title: "",
      row2_title: "",
      row3_title: "",
    },
    info1: "",
  });

  useEffect(() => {
    if (order?.orderType === ORDER_TYPE_BOOK_PURCHASE) {
      setDescription((prevDescription) => ({
        ...prevDescription, // Copy the previous state
        title: "Purchase Confirmation",
        titleSummary: "Order Summary",
        processDate: convertTimestampToDateTime(order?.timestamp, true),
        info1: `${user.firstName}, thank you for your order!`,
      }));
    } else if (order?.orderType === ORDER_TYPE_CREDITS_PURCHASE) {
      setDescription((prevDescription) => ({
        ...prevDescription, // Copy the previous state
        title: "Image Credits Purchase Confirmation",
        titleSummary: "Image credit Purchase Summary",
        processDate: convertTimestampToDateTime(order?.timestamp, true),
        info1: `${user.firstName}, thank you for purchasing Image credits.`,
      }));
    } else if (order?.orderType === ORDER_TYPE_SUBSCRIPTION_PURCHASE) {
      setDescription((prevDescription) => ({
        ...prevDescription, // Copy the previous state
        title: "Purchase Confirmation",
        titleSummary: "Plan Subscription Summary",
        processDate: convertTimestampToDateTime(order?.timestamp, true),
        info1: `${user?.firstName}, thank you for subscribing to plan <strong>${order?.extraInfo?.plan}</strong>.`,
      }));
    }

    setSubTotal(
      (
        parseFloat(bookPrice) * parseFloat(order?.quantity) +
        parseFloat(order?.additionalPageCost || 0) -
        parseFloat(order?.couponDiscount || 0)
      ).toFixed(2)
    );
  }, [order]);

  const bookPurchasePriceAfterDiscount = (order) => {
    let discountedPrice = (
      parseFloat(bookPrice) * parseFloat(order?.quantity) +
      parseFloat(order?.additionalPageCost || 0) -
      parseFloat(order?.couponDiscount || 0)
    ).toFixed(2);
    if (discountedPrice < 1) {
      discountedPrice = 0;
    }
    return discountedPrice;
  };

  const calculateBookPurchaseTotalAmount = (order) => {
    // console.log("price", order?.price);
    let total = 0;
    // let total = (parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)).toFixed(2);
    let freePhysicalBookCreated = order?.freePhysicalBookCreated || 0;
    let booksTocharge = parseInt(order?.quantity) - parseInt(freePhysicalBookCreated);
    // if (order?.orderType === "book"){
    total = (
      parseFloat(order?.price) -
      parseFloat(order?.couponDiscount || 0) +
      parseFloat(order?.shippingAmount)
    ).toFixed(2);
    // }

    // const subTotal = parseFloat(
    //   parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)
    // ).toFixed(2);
    if (total <= 0) {
      total = 0;
    }
    return total;
  };

  const checkCoupon = (order) => {
    if (order?.coupon?.startsWith("GC")) {
      return "Gift Card";
    }
    return "discount";
  };

  return (
    <div>
      <button
        className="w-20 flex justify-center bg-storygreen text-white font-bold rounded-md text-base p-2 mb-4 float-right"
        onClick={onClose}
      >
        Back
      </button>
      {/* <div className="text-storyblue text-lg text-left flex flex-col gap-8"> */}
      {/* <h1 className="text-2xl font-bold">{description?.title}</h1> */}
      <div
        className={`w-[18rem] md:w-full text-storyblue text-lg text-left flex ${
          order?.orderType === ORDER_TYPE_BOOK_PURCHASE
            ? "items-start md:[align-items:normal]"
            : "items-center"
        }  flex-col gap-8`}
      >
        <h1 className="text-xl md:text-2xl font-bold">{description?.title}</h1>
        <p>
          <div dangerouslySetInnerHTML={{ __html: description?.info1 }} />
        </p>

        {order?.orderType === ORDER_TYPE_BOOK_PURCHASE && (
          <p>
            We’ve received your order and will contact you as soon as your package is shipped. You can find
            your purchase information below.
          </p>
        )}

        {order?.orderType === ORDER_TYPE_CREDITS_PURCHASE && (
          <p>
            You have purchased <strong>{order?.quantity}</strong> Image Credits for{" "}
            <strong>$ {order?.price}</strong>
          </p>
        )}

        {order?.orderType === ORDER_TYPE_SUBSCRIPTION_PURCHASE && (
          <div>
            <p>
              <strong>Image Credits:</strong> {order?.extraInfo?.credits}
            </p>
            <p>
              <strong>Additional Image Credits:</strong> {order?.extraInfo?.additionImageCreditsInfo}
            </p>
            <p>
              <strong>Unlimited Downloads:</strong> {order?.extraInfo?.unlimitedDownloads}
            </p>
          </div>
        )}

        <div className="text-center">
          <p className="text-[16px] font-bold">{description?.titleSummary}</p>
          <p>{description?.processDate}</p>
        </div>
        <div className="flex justify-center items-center flex-wrap md:flex-nowrap gap-4 md:gap-16">
          <img src="/bookcover.png" alt="pic" className="w-40 h-40" />
          {order?.orderType === ORDER_TYPE_SUBSCRIPTION_PURCHASE && (
            <div className="font-bold text-sm flex flex-col gap-2">
              <div className="flex">
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>Plan Cost:</p>

                  {/* <p>discount:</p> */}
                  <p>{checkCoupon(order)}:</p>
                </div>
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>$ {order?.price}</p>
                  {/* <p>$ {order?.couponDiscount}</p> */}
                  <p>
                    ${" "}
                    {parseFloat(
                      parseFloat(order?.price) + parseFloat(order?.additionalPageCost || 0)
                    ).toFixed(2) <= 0
                      ? 0.0
                      : order?.couponDiscount}
                  </p>
                </div>
              </div>
              <hr className="border-1 w-56" style={{ borderColor: "gray" }} />
              <div className="flex  text-left">
                <p className="w-44">Total Price:</p>
                {/* <p>$ {parseFloat(order?.price) - parseFloat(order?.couponDiscount || 0)}</p> */}
                <p>${calculateBookPurchaseTotalAmount(order)}</p>
              </div>
            </div>
          )}

          {order?.orderType === ORDER_TYPE_CREDITS_PURCHASE && (
            <div className="font-bold text-sm flex flex-col gap-2">
              <div className="flex">
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>Image Credit Price:</p>
                  <p>Discount:</p>
                </div>
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>$ {order?.price}</p>
                  <p>$ {order?.couponDiscount}</p>
                </div>
              </div>
              <hr className="border-1 w-56" style={{ borderColor: "gray" }} />
              <div className="flex  text-left">
                <p className="w-44">Total Price:</p>
                <p>$ {parseFloat(order?.price) - parseFloat(order?.couponDiscount || 0)}</p>
              </div>
            </div>
          )}

          {order?.orderType === ORDER_TYPE_BOOK_PURCHASE && (
            <div className="flex flex-col">
              <div className="font-bold text-sm">
                <div className="flex">
                  <div className="flex flex-col text-left gap-2 w-[]">
                    <p>{order?.extraInfo?.bookName}</p>
                    <p>Additional Pages:</p>
                    <p>Discount:</p>
                    <p>Price after Discount:</p>
                    <p>Quantity:</p>
                  </div>
                  <div className="flex flex-col text-left gap-2 w-44">
                    <p>$ {bookPrice}</p>
                    <p>$ {order?.additionalPageCost || 0}</p>
                    <p>$ {order?.couponDiscount || 0}</p>
                    <p>$ {bookPurchasePriceAfterDiscount(order)}</p>

                    <p> {order?.quantity}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {order?.orderType === ORDER_TYPE_BOOK_PURCHASE && (
          <div className="flex flex-col gap-4">
            <h1 className="text-center font-bold">Order Total</h1>
            <div className="font-bold flex flex-col justify-evenly items-center gap-4 text-sm">
              <div className="flex">
                <div className="flex flex-col text-left gap-2 w-[9rem] md:w-72">
                  <p>Subtotal Price:</p>
                  <p>Shipping Price:</p>
                </div>
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>$ {subTotal < 1 ? 0 : subTotal}</p>
                  <p>$ {order?.shippingAmount}</p>
                </div>
              </div>
              <hr className="w-[80%] md:w-[40%] border-1 mx-16" style={{ borderColor: "gray" }} />
              <div className="flex">
                <div className="flex flex-col text-left gap-2 w-[8rem] md:w-72">
                  <p>Total Price:</p>
                </div>
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>$ {calculateBookPurchaseTotalAmount(order)}</p>
                </div>
              </div>
            </div>

            <h1 className="text-center font-bold">Billing and Shipping</h1>
            <div className="font-bold flex flex-col justify-evenly items-center gap-4 text-sm">
              <div className="flex">
                <div className="flex flex-col text-left gap-2 w-[8rem] md:w-72">
                  <p>Billing From</p>
                  <p>{order?.billingCardHolderName}</p>
                  <p>
                    {order?.billingAddress1} {order?.billingAddress2}
                  </p>
                  <p>
                    {order?.billingCity},{order?.billingState} {order?.billingPostalCode}
                  </p>
                  <p>US</p>
                  <br />
                </div>
                <div className="flex flex-col text-left gap-2 w-44">
                  <p>Ship To</p>
                  <p>
                    {" "}
                    <p>
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p>
                      {order?.shippingAddress1} {order?.shippingAddress2}
                    </p>
                    <p>
                      {order?.shippingCity},{order?.shippingState} {order?.shippingZip}
                    </p>
                    <p>US</p>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <p className="text-center text-sm font-bold">
          Thank you for your business and we look forward to serving you in the future!
        </p>
      </div>
    </div>
  );
};
export default PurchaseConfirmation;
