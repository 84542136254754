import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBook, faUser as farUser, faImages } from "@fortawesome/free-solid-svg-icons";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useUser } from "../../context/userContext";
import { Link, useNavigate } from "react-router-dom";
import { callAPI } from "../../services/apiService";
import { Toast } from "../Common/Toast";
import { useApp } from "../../context/appContext";
import Dialogs from "../Common/Dialog";
import "../../App.css";

import {
  BROWSER_REFRESH_POPUP_PARAM,
  HTTP_STATUS_200,
  LOCAL_STORAGE_BOOK_ID,
  LOCAL_STORAGE_EDIT_MODE,
  LOCAL_STORAGE_STORY_STEP,
  LOCAL_STORAGE_TOKEN,
  PROMO_PLAN,
} from "../../helper/Constant";
import { useStory } from "../../context/storyContext";
import StepsBar from "../StepsBar/StepsBar";
import "../../App.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavigationBar = () => {
  const { user, setUser, showLogin, setShowLogin, singleBookPlan } = useUser();
  const { currentStep, setCurrentStep, setDraftId, startNewStory, setStartNewStory, setBookData, bookData } =
    useApp();
  const { setStoryData, setIllustrationCharacter } = useStory();
  const navigateTo = useNavigate();
  const [bookId, setBookId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [storiesRemaining, setStoriesRemaining] = useState(0);

  const [navigation, setNavigation] = useState([
    { name: "Create New Story", href: "/", current: false },
    { name: "My Library", href: "/library", current: false },
    // { name: "Buy Illustration & Story Credits", href: "/ImageTokens", current: false },
    { name: "Stories:", href: "#", current: false },
  ]);

  const logo = "/logo.webp"; // Logo path

  const handleLogout = async () => {
    try {
      await signOut(auth);

      setUser(null); 
      localStorage.removeItem(LOCAL_STORAGE_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
      localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
      localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
      localStorage.removeItem(PROMO_PLAN);
  
      setCurrentStep(0);
      setDraftId(null);
  
      window.location.href = `/#/?${BROWSER_REFRESH_POPUP_PARAM}=no`;
      window.location.reload();
  
    } catch (error) {
      console.error("Error during sign out:", error);
  
      Toast("Failed to log out. Please try again.", true);
    }
  };
  
  useEffect(() => {
    const idBook = localStorage.getItem(LOCAL_STORAGE_BOOK_ID);

    if (idBook && user && user?.email) setBookId(idBook);
  }, [currentStep, localStorage.getItem(LOCAL_STORAGE_BOOK_ID)]);

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_EDIT_MODE)) setEditMode(true);
    else setEditMode(false);
  }, [localStorage.getItem(LOCAL_STORAGE_EDIT_MODE)]);

  useEffect(() => {
    if (user) {
      setStoriesRemaining(parseInt(user?.booksCreateLimit || 0) - parseInt(user?.booksCreatedCounter || 0));
      // console.log("user?.planRef", user?.planRef?._path?.segments[1]);
    }
  }, [user]);

  useEffect(() => {
    if (user && user?.plan && user?.verified) {
      let storiesRemaining = parseInt(user?.booksCreateLimit || 0) - parseInt(user?.booksCreatedCounter || 0);
      if (singleBookPlan) {
        setNavigation([
          { name: "Create New Story", href: "/", current: false },
          { name: "My Library", href: "/library", current: false },
          { name: `Stories`, href: "#", current: false },
        ]);
      } else {
        setNavigation([
          { name: "Create New Story", href: "/", current: false },
          { name: "My Library", href: "/library", current: false },
          // { name: "Buy Illustration & Story Credits", href: "/ImageTokens", current: false },
          { name: `Stories`, href: "#", current: false },
        ]);
      }
    } else {
      if (user && !user?.plan && user?.verified) {
        setNavigation([
          { name: "Purchase Plan", href: "/myPlans", current: false },
          { name: "My Library", href: "/library", current: false },
        ]);
      } else {
        setNavigation([]);
      }
    }
  }, [user]);

  const handleSaveDraft = async () => {
    setLoader(true);
    const response = await callAPI(`api/save-story-draft`, { email: user?.email, bookId, step: currentStep });
    if (response?.status === HTTP_STATUS_200) {
      localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
      localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
      localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
      setCurrentStep(0);
      // window.location.pathname
      // here i want to add query parameter plan with value Promo with any pathname before reloading the page
      window.location.href = `${window.location.href}?${BROWSER_REFRESH_POPUP_PARAM}=no`;

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      Toast("Unexpected Error While Saving book as Draft", true);
      setLoader(false);
    }
  };

  const handleStartNewStory = () => {
    setCurrentStep(1);
    setDraftId(null);
    localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
    localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
    localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
    setStoryData({
      characterBuilding: false,
      showLibrary: false,
      storybuildingError: false,
      isDummyPic: false,
      creditsPerBookLimit: 0,
    });
    // setIllustrationCharacter(null);
    setBookData(null);
    // window.location.reload(); // Refresh the app
    navigateTo("/");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (currentStep > 1) setStartNewStory(false);
  }, [currentStep]);

  const NavigationItem = (item) => {
    return (
      <>
        {item.name === "Stories" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
              icon={faBook}
              className="cursor-pointer"
              title="Remaining Stories"
              style={{
                color: "#042c6e",
                marginTop: "12px",
                marginLeft: "6px",
                fontSize: "24px",
                marginBottom: "6px",
              }}
            />
            <span className="text-sm pl-1 mt-2 text-storyblue">{storiesRemaining}</span>
          </div>
        ) : (
          <Link
            key={item.name}
            to={item.href}
            className={classNames(
              // false.current ? "bg-transparent text-storyblue underline" : "text-storygreen hover:text-storygreen",
              // "px-3 py-2 rounded-md text-sm font-bold flex gap-2 items-center h-11 "
              item.current
                ? "bg-transparent text-storyblue underline"
                : "text-storygreen hover:text-storyblue bg-[#cde5e0] border border-storygreen rounded-md hover:border-storyblue", // Add hover effect for border
              "px-2 py-2 text-sm font-bold flex gap-2 items-center h-9"
            )}
            // className={classNames(
            //   item.current
            //     ? "bg-transparent text-storyblue underline"
            //     : "text-storyblue hover:text-storygreen",
            //   "px-3 py-2 rounded-md text-sm font-bold"
            // )}
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Link>
        )}
      </>
    );
  };

  const NavigateLink = (title, to = null, onclick = null) => {
    return (
      <Link
        className={classNames(
          // false.current ? "bg-transparent text-storyblue underline" : "text-storygreen hover:text-storygreen",
          // "px-3 py-2 rounded-md text-sm font-bold flex gap-2 items-center h-11 "
          false.current
            ? "bg-transparent text-storyblue underline"
            : "text-storygreen hover:text-storyblue bg-[#cde5e0] border border-storygreen rounded-md hover:border-storyblue", // Add hover effect for border
          "px-2 sm:py-0 md:py-0  py-2 sm:text-xs lg:text-sm font-bold flex gap-2 items-center h-9"
        )}
        to={to ? to : null}
        onClick={() => {
          if (onclick) onclick();
        }}
      >
        {title}
      </Link>
    );
  };
  // console.log("bookdata", bookData);

  return (
    <>
      <Disclosure as="nav" className="bg-white py-[1.3rem] p-[.3rem] md:p-6  header-bg">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl sm:px-6 h-10 md:h-16">
              <div className="relative flex items-center justify-between">
                {/* Mobile menu button */}
                <div className="flex-1 flex items-center  sm:items-stretch justify-between">
                  <div className="sm:hidden md:absolute inset-y-0 left-0 flex items-center ">
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  {/* User profile dropdown (centered on mobile) */}
                  <Link
                    to="http://buildmystory.ai/"
                    target="_blank"
                    className="flex-shrink-0 flex items-center"
                  >
                    <img
                      className="h-[2.5rem] lg:h-[51px] w-[8.5rem]  lg:w-[11rem]"
                      src={logo}
                      alt="Build My Story"
                    />
                  </Link>
                  {user ? (
                    <div className="relative md:hidden ">
                      <Menu as="div" className="relative inline-block text-center">
                        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-md hover:bg-gray-50">
                          <FontAwesomeIcon icon={farUser} className="mr-2" />

                          <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-20  mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/updateProfile"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  My Profile
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/myPlans"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  My Plan
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/library"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  My Library
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/OrderHistory"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  My Orders
                                </Link>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/Payments"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block text-left px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    My Payments
                                  </Link>
                                )}
                              </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={handleLogout}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-full text-left px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ) : !showLogin ? (
                    <>
                      <button
                        onClick={() => {
                          setShowLogin(true);
                        }}
                        className="bg-transparent md:hidden text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                      >
                        Log In
                      </button>
                      {/* |
                      <Link
                        to="/admin"
                        className="bg-transparent text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                      >
                        Admin Log In
                      </Link> */}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setShowLogin(false);
                      }}
                      className="bg-transparent md:hidden text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                    >
                      Sign Up
                    </button>
                  )}{" "}
                  {/* Logo as Home link */}
                  {/* <Link
                    to="http://buildmystory.ai/"
                    target="_blank"
                    className="flex-shrink-0 flex items-center"
                  >
                    <img
                      className="h-[2.5rem] lg:h-[51px] w-[8.5rem]  lg:w-[11rem]"
                      src={logo}
                      alt="Build My Story"
                    />
                  </Link> */}
                  <div className="hidden sm:flex width:[50%] justify-between  h-16 gap-4 place-items-end ">
                    <div
                      className="ml-2"
                      // className="hidden sm:flex h-16 place-items-end border border-red-500"
                    >
                      <div className="space-x-4  flex gap-2 mb-1">
                        {user?.email && user.verified && !bookId ? (
                          navigation.map((item) => NavigationItem(item))
                        ) : user?.plan && user?.email && user?.verified && bookId ? (
                          <div className="flex gap-2 items-center">
                            {currentStep === 6
                              ? NavigateLink("Create New Story", "/", handleStartNewStory)
                              : bookId && window.location.hash.toString() !== "#/"
                              ? NavigateLink("Go To Story", "/")
                              : currentStep !== 0
                              ? NavigateLink("Save To Library & Create New Story", null, () =>
                                  setOpenDialog(true)
                                )
                              : NavigateLink("Create New Story", "/", handleStartNewStory)}
                            {NavigateLink("My Library", "/library")}
                            {/* {!singleBookPlan &&
                              NavigateLink("Buy Illustration & Story Credits", "/ImageTokens")} */}
                            {/* {NavigateLink(`Stories: ${storiesRemaining}`, "#")} */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <FontAwesomeIcon
                                icon={faBook}
                                className="cursor-pointer"
                                title="Remaining Stories"
                                style={{
                                  color: "#042c6e",
                                  marginTop: "12px",
                                  marginLeft: "6px",
                                  fontSize: "24px",
                                  marginBottom: "6px",
                                }}
                              />
                              <span className="text-sm pl-1 mt-2 text-storyblue">{storiesRemaining}</span>
                            </div>
                            {/* {parseInt(bookData?.creditsPerBookLimit) >= 0 && (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <FontAwesomeIcon
                                  icon={faImages}
                                  className="cursor-pointer"
                                  title="Remaining Illustrations"
                                  style={{
                                    color: "#042c6e",
                                    marginTop: "12px",
                                    marginLeft: "6px",
                                    fontSize: "24px",
                                    marginBottom: "6px",
                                  }}
                                />
                                <span className="text-sm pl-1 mt-2 text-storyblue">
                                  {bookData?.creditsPerBookLimit}
                                </span>
                              </div>
                            )} */}
                            {/* {parseInt(bookData?.creditsPerBookLimit) >= 0 &&
                            NavigateLink(`Illustrations: ${bookData?.creditsPerBookLimit}`, "#")} */}
                          </div>
                        ) : (
                          !user?.plan && user?.email && user?.verified ? (
                            <>
                              {NavigateLink("Purchase Plan", "/myPlans")}
                              {NavigateLink("My Library", "/library")}
                            </>
                          ) : ""
                        
                        )}
                      </div>
                    </div>
                    <div className="flex items-center mb-1">
                      {/* <Link
                      to="https://buildmystory.ai/guide/"
                      target="_blank"
                      className="flex-shrink-0 flex items-center mr-2"
                    >
                      <img
                        className="h-[20px] md:h-[30px]"
                        src={HelpImage}
                        alt="Build My Story Guide"
                        title="Guide"
                      />
                    </Link> */}
                      {user ? (
                        <>
                          {/* Profile dropdown */}
                          <Menu as="div" className="relative">
                            <div>
                              <Menu.Button className="flex rounded-full text-sm focus:outline-none items-center">
                                <span className="sr-only">Open user menu</span>
                                {/* <img
                                  className="h-8 w-8 rounded-full"
                                  src={user?.photoURL || Avatar}
                                  alt={user?.firstName || user?.email}
                                /> */}
                                <FontAwesomeIcon
                                  icon={farUser}
                                  className="cursor-pointer"
                                  title="User Menu"
                                  style={{
                                    marginBottom: "6px",
                                    marginLeft: "6px",
                                    fontSize: "24px",
                                    color: "#042c6e",
                                  }}
                                />
                                {/* <span className="hidden md:block pt-2 pl-2">
                                  {user?.firstName || user?.email}
                                </span> */}
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="cursor-pointer"
                                  title="User Menu"
                                  style={{ color: "#042c6e", marginLeft: "6px" }}
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-20  mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="/updateProfile"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block text-left px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      My Profile
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="/myPlans"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block text-left px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      My Plan
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="/library"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block text-left px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      My Library
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="/OrderHistory"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block text-left px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      My Orders
                                    </Link>
                                  )}
                                </Menu.Item>
                                {/* <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to="/Payments"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block text-left px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    My Payments
                                  </Link>
                                )}
                              </Menu.Item> */}
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={handleLogout}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block w-full text-left px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      Sign out
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </>
                      ) : !showLogin ? (
                        <>
                          <button
                            onClick={() => {
                              setShowLogin(true);
                            }}
                            className="bg-transparent text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                          >
                            Log In
                          </button>
                          {/* |
                      <Link
                        to="/admin"
                        className="bg-transparent text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                      >
                        Admin Log In
                      </Link> */}
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setShowLogin(false);
                          }}
                          className="bg-transparent text-storyblue px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white"
                        >
                          Sign Up
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile menu */}
            <Disclosure.Panel className="sm:hidden flex flex-col  items-start">
              <div className=" pt-2 pb-3 space-y-1 ">
                {user?.email && user.verified && !bookId ? (
                  navigation.map((item) => NavigationItem(item))
                ) : user?.plan && user?.email && user?.verified && bookId ? (
                  <div className="flex flex-col    gap-2">
                    {currentStep === 6
                      ? NavigateLink("Create New Story", "/", handleStartNewStory)
                      : bookId && window.location.hash.toString() !== "#/"
                      ? NavigateLink("Go To Story", "/")
                      : currentStep !== 0
                      ? NavigateLink("Save To Library & Create New Story", null, () => setOpenDialog(true))
                      : NavigateLink("Create New Story", "/", handleStartNewStory)}
                    {NavigateLink("My Library", "/library")}
                    {!singleBookPlan && NavigateLink("Buy Illustration & Story Credits", "/ImageTokens")}
                    {/* {NavigateLink(`Stories: ${storiesRemaining}`, "#")} */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FontAwesomeIcon
                        icon={faBook}
                        className="cursor-pointer"
                        title="Remaining Stories"
                        style={{
                          color: "#042c6e",
                          marginTop: "12px",
                          marginLeft: "6px",
                          fontSize: "24px",
                          marginBottom: "6px",
                        }}
                      />
                      <span className="text-sm pl-1 mt-2 text-storyblue">{storiesRemaining}</span>
                    </div>
                    {parseInt(bookData?.creditsPerBookLimit) >= 0 && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon
                          icon={faImages}
                          className="cursor-pointer"
                          title="Remaining Stories"
                          style={{
                            color: "#042c6e",
                            marginTop: "12px",
                            marginLeft: "6px",
                            fontSize: "24px",
                            marginBottom: "6px",
                          }}
                        />
                        <span className="text-sm pl-1 mt-2 text-storyblue">
                          {bookData?.creditsPerBookLimit}
                        </span>
                      </div>
                    )}
                    {/* {parseInt(bookData?.creditsPerBookLimit) >= 0 &&
                            NavigateLink(`Illustrations: ${bookData?.creditsPerBookLimit}`, "#")} */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Dialogs
        openDialog={openDialog}
        handelClose={handleClose}
        handelProcess={handleSaveDraft}
        confirmMessage="Are you sure you want to save current Story & start new Story?"
        loader={loader}
      />
      <div className="bg-storytan">{user && user?.verified && <StepsBar currentStep={currentStep} />}</div>
    </>
  );
};

export default NavigationBar;
